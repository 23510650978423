<template>
  <SingleModelLayout
    :model.sync="model"
    :route-back="{
      name: route.index
    }"
    @refresh-model="getModel"
    @delete-model="deleteModel"
  >

    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>

    <template #left>
      <div>
        <h3>Customer Info</h3>
        <div class="bg-gray-200 rounded mb-4 p-3">
            <div>
              <h4>Payment Info</h4>
              <div class="p-2">
                <p class="hover:text-primary">{{ paymentType }}</p>
              </div>
            </div>
            <div>
              <h4>Price Bracket</h4>
              <div class="p-2">
                <p class="hover:text-primary">{{ priceBracket }}</p>
              </div>
            </div>
            <div>
              <h4>Contact</h4>
              <div class="p-2 flex gap-4">
                <span>Email: </span>
                <a :href="`mailto:${model.customer_email}`" class="hover:text-primary">{{ model.customer_email }}</a>
              </div>
              <div class="p-2 flex gap-4">
                <span>Mobile: </span>
                <a :href="`tel:${model.customer_mobile}`" class="hover:text-primary">{{ model.customer_mobile }}</a>
              </div>
            </div>
          </div>
      </div>
      <div>
        <vue-data
        v-model="model.billing_status"
        :value="model.billing_status"
        :edit="edit"
        :options="[
         { label: 'Awaiting Payment', value: 'STATUS_AWAITING_PAYMENT'},
         { label: 'Paid', value: 'STATUS_PAID'}
        ]"
        label="Order Status"
        label-key="label"
        value-key="value"
        type="select"
        class="mb-2"
        />
      </div>
    </template>
    <template #main>
      <div>
        <h3 class="my-4">Order Details</h3>
        <div>
          <!-- <pre>{{ model }}</pre> -->
        </div>
        <div class="lg:my-5 p-gap bg-white rounded-lg overflow-x-scroll">
          <table class="table-auto w-full">
            <thead class="border-b">
              <tr>
                <th>Name</th>
                <th>Size</th>
                <th>Part number</th>
                <th>Quantity</th>
                <th>Unit Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in products" :key="product.id" class="text-2xl text-center">
                <td>{{ product.name }}</td>
                <td>{{ product.pivot.order_data['Size'] }}</td>
                <td>{{ product.pivot.order_data['Part No'] }}</td>
                <td>{{ product.pivot.qty }}</td>
                <td>{{ $currency(parseFloat(product.pivot.subtotal / product.pivot.qty).toFixed(2)) }}</td>
              </tr>
              <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="text-right">Subtotal</td>
                  <td class="text-right">{{ $currency(model.sub_total) }}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="text-right">Tax(included)</td>
                  <td class="text-right">{{ $currency(model.tax) }}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="text-right">Shipping</td>
                  <td class="text-right">{{ model.shipping.shipping_cost ? model.shipping.shipping_cost.toLocaleString('en-UK', {'style':'currency','currency':'GBP'}) : 0 }}</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td class="text-right border-t font-bold">Total</td>
                  <td class="text-right border-t font-bold">{{ $currency(model.grand_total) }}</td>
                </tr>
            </tbody>
          </table>
        </div>
        <div class="my-10 py-4">
          <h3 class="my-4">Payment Information</h3>
          <div class="lg:my-5 p-gap bg-white rounded-lg">
            <table class="table-auto w-full">
              <thead>
                <tr>
                  <th>Method</th>
                  <th>Reference</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{ payment.provider }}</td>
                  <td>{{ payment.provider_id }}</td>
                  <td>{{ model.billing_status_label }}</td>
                  <td>{{ model.grand_total.toLocaleString('en-UK', {'style':'currency','currency':'GBP'}) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <div>
        <h3>Order Shipping Info</h3>
        <div class="my-2">
          <div class="bg-gray-200 rounded mb-4 p-3">
            <h4>Billing Address</h4>
            <div class="p-2">
              <p>{{ model.customer_name }}</p>
              <p>{{ address }}</p>
              <p>{{ model.customer_mobile }}</p>
            </div>
          </div>
        </div>
        <div class="my-2">
          <div class="bg-gray-200 rounded mb-4 p-3">
            <h4>Order Notes</h4>
            <div class="p-2">
              <p>{{ model.order_notes ? model.order_notes : 'N/A' }}</p>
            </div>
          </div>
        </div>
        <div class="my-2">
          <div class="bg-gray-200 rounded mb-4 p-3">
            <h4>Shipping Charges</h4>
            <div class="p-2">
              <div class="flex gap-2">
                <p class="font-medium">Order Items Total Weight:</p>
                <p>{{ model.shipping.weight }} (grams)</p>
              </div>
              <div class="flex gap-2">
                <p class="font-medium">Order Items Total Length:</p>
                <p>{{ model.shipping.length }} (mm)</p>
              </div>
              <div class="mt-4">
                <vue-data
                  v-model="model.shipping.shipping_cost"
                  :value="model.shipping.shipping_cost.toLocaleString('en-UK', {'style':'currency','currency':'GBP'})"
                  :edit="model.billing_status_label !== 'Paid' && edit"
                  class="mb-2"
                  label="Shipping Cost"
                  description="The Shipping charges applied to this order"
                  />
              </div>
            </div>
          </div>
        </div>
        <div v-show="tradeAccountUser && model.billing_status_label !== 'Paid'" class="my-2">
          <div class="bg-gray-200 rounded mb-4 p-3">
            <h4>Send Email Invoice</h4>
            <div class="p-2 flex flex-col mt-4 lg:items-center gap-gap-sm">
              <vue-button
                :icon="['fas', 'paper-plane']"
                :loading="sending"
                size="2x"
                @click="sendInvoice"
                outline
              >
              {{ sending ? 'Sending' : 'Send Invoice' }}
              </vue-button>
              <p>{{ invoiceInfo }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SingleModelLayout>
</template>

<script>
import { cloneDeep, isNull } from 'lodash'
import SingleModelLayout from '@/components/models/SingleModelLayout'
import TitleSummaryCard from '@/components/models/TitleSummaryCard'
import UsesTokens from '../../mixins/UsesTokens'

export default {

  name: 'OrdersIndex',

  mixins: [UsesTokens],

  components: {
    SingleModelLayout,
    TitleSummaryCard
  },

  data () {
    return {
      edit: false,
      loading: false,
      sending: false,
      model: false,
      invoiceInfo: '',
      route: {
        apiEndpoint: 'orders',
        laravelModel: 'Order',
        index: 'orders.index'
      }
    }
  },

  computed: {
    value () {
      return this.model.value ?? {}
    },
    billingInfo () {
      return this.model.customer.billingInfo
    },
    address () {
      const customer = this.model.customer
      const billingInfo = isNull(customer) ? null : customer.billingInfo
      let customerAddress = ''
      if (billingInfo) {
        customerAddress = `${billingInfo.billing_address ?? ''}, ${billingInfo.billing_postcode ?? ''}, ${billingInfo.town ?? ''} ${billingInfo.county ?? ''} ${billingInfo.country ?? ''}`
      }
      return customerAddress
    },
    products () {
      return this.model.products
    },
    payment () {
      return this.model.payment
    },
    tradeAccountUser () {
      const customer = this.model.customer
      const paymentType = isNull(customer) ? null : customer.price_bracket
      let tradeAccount
      const tradeAccountUsers = [1, 2, 3]
      if (tradeAccountUsers.includes(paymentType)) {
        tradeAccount = true
      } else {
        tradeAccount = false
      }
      return tradeAccount
    },

    priceBracket () {
      const customer = this.model.customer
      const paymentType = isNull(customer) ? null : customer.price_bracket
      let payBracket = ''

      if (paymentType === 1) {
        payBracket = 'Band 1 - Trade Price'
      } else if (paymentType === 2) {
        payBracket = 'Band 2 - Trade Price'
      } else if (paymentType === 3) {
        payBracket = 'Band 3 - Trade Price'
      } else {
        payBracket = 'Band 4 - Non-Trade Price'
      }
      return payBracket
    },
    paymentType () {
      const customer = this.model.customer
      const paymentType = isNull(customer) ? null : customer.payment_type
      let payType = ''
      if (paymentType === null) {
        payType = 'Payment term not set'
      } else if (paymentType === 1) {
        payType = 'Pay Online - at basket'
      } else {
        payType = 'Pay Offline - on account'
      }
      return payType
    }
  },

  mounted () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`${this.route.apiEndpoint}/${this.$route.params.model}`, {
        params: {
          withPivot: true,
          with: ['products', 'payments', 'customer', 'customer.billingInfo']
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.updatePaymentState()
      this.$api.post(`orders/shipping/${this.model.id}`, this.model)
        .then((res) => {
          console.log(res)
          this.edit = false
          this.loading = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        }).finally(() => {
          this.getModel()
        })
    },

    deleteModel () {
      this.loading = true
      this.$api.delete(`${this.route.apiEndpoint}/${this.$route.params.model}`)
        .then((res) => {
          this.$router.push({
            name: this.route.index
          })
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
    },

    sendInvoice () {
      this.sending = true
      this.invoiceInfo = ''
      this.getToken().then(() => {
        this.saveModel()
      }).then(() => {
        this.sendMail()
      })
    },

    sendMail () {
      this.getToken().then(() => {
        this.$api.post(`orders/${this.model.id}/mail`)
          .then((res) => {
            this.invoiceInfo = res.message
          })
          .catch((err) => {
            this.$store.commit('error/addError', err)
          })
          .finally(() => {
            this.sending = false
          })
      })
    },

    updatePaymentState () {
      this.$api.post(`orders/${this.model.id}/paid`, { order_status: this.model.billing_status })
        .then((res) => {
          console.log(res)
        }).catch((err) => {
          // this.$store.commit('error/addError', err)
          console.log('err', err)
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>

<style>

</style>
